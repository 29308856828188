import {
    EntityClassification,
    PipelineDocumentTicket,
    PipelineJobStage,
    PipelineJobStatus,
} from './types'

export const getEntityClassificationUiName = (className: string) => {
    return className
        .split('_')
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ')
}

export const getEntityValueTypeUiName = (className: string) => {
    return className
        .split('_')
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ')
}

export const orderedClassifications: EntityClassification[] = [
    'resident',
    'lease_start',
    'lease_end',
    'unit_type',
    'bldg_unit',
    'monthly_rent',
    'total_rent',
    'installments',
    'security_deposit',
    'late_charges',
    'reletting_charges',
    'guarantor',
    'guarantor_email',
    'approved_date',
    'balance',
    'completed_date',
    'employee_discount',
    'guarantor_waiver_fee',
    'lease_status',
    'lease_term',
    'lease_term_name',
    'liability_insurance',
    'market_rent',
    'parking_fee',
    'pet_rent',
    'amenity_fee',
    'rent_concessions',
    'short_term_fee',
    'square_footage',
    'unit_status',
    'utility_electricity',
    'utility_trash',
    'renters_insurance_required',
    'personal_liability_required',
    'renters_insurance_provider',
    'required_policy_amount',
    'monthly_cost_of_insurance',
    'lease_contract_date',
    'animals_approved',
    'animal_deposit',
    'additional_fees',
    'pet_1_type',
    'pet_1_breed',
    'pet_1_weight',
    'pet_1_age',
    'pet_1_house_broken',
    'pet_2_type',
    'pet_2_breed',
    'pet_2_weight',
    'pet_2_age',
    'pet_2_house_broken',
    'pet_3_type',
    'pet_3_breed',
    'pet_3_weight',
    'pet_3_age',
    'pet_3_house_broken',
]

export type ClassificationDataType = {
    type: 'string' | 'number' | 'date' | 'boolean'
    format: 'string' | 'number' | 'date' | 'boolean' | 'currency'
}

export const classificationDataTypes: Map<
    EntityClassification,
    ClassificationDataType
> = new Map([
    ['resident', { type: 'string', format: 'string' }],
    ['lease_start', { type: 'date', format: 'date' }],
    ['lease_end', { type: 'date', format: 'date' }],
    ['unit_type', { type: 'string', format: 'string' }],
    ['bldg_unit', { type: 'string', format: 'string' }],
    ['monthly_rent', { type: 'number', format: 'currency' }],
    ['total_rent', { type: 'number', format: 'currency' }],
    ['installments', { type: 'number', format: 'number' }],
    ['security_deposit', { type: 'number', format: 'currency' }],
    ['late_charges', { type: 'number', format: 'currency' }],
    ['reletting_charges', { type: 'number', format: 'currency' }],
    ['guarantor', { type: 'string', format: 'string' }],
    ['guarantor_email', { type: 'string', format: 'string' }],
    ['approved_date', { type: 'date', format: 'date' }],
    ['balance', { type: 'number', format: 'currency' }],
    ['completed_date', { type: 'date', format: 'date' }],
    ['employee_discount', { type: 'number', format: 'currency' }],
    ['guarantor_waiver_fee', { type: 'number', format: 'currency' }],
    ['lease_status', { type: 'string', format: 'string' }],
    ['lease_term', { type: 'number', format: 'number' }],
    ['lease_term_name', { type: 'string', format: 'string' }],
    ['liability_insurance', { type: 'string', format: 'string' }],
    ['market_rent', { type: 'number', format: 'currency' }],
    ['parking_fee', { type: 'number', format: 'currency' }],
    ['pet_rent', { type: 'number', format: 'currency' }],
    ['rent_concessions', { type: 'number', format: 'currency' }],
    ['short_term_fee', { type: 'number', format: 'currency' }],
    ['square_footage', { type: 'number', format: 'number' }],
    ['unit_status', { type: 'string', format: 'string' }],
    ['utility_trash', { type: 'number', format: 'currency' }],
    ['renters_insurance_required', { type: 'boolean', format: 'boolean' }],
    ['personal_liability_required', { type: 'boolean', format: 'boolean' }],
    ['renters_insurance_provider', { type: 'string', format: 'string' }],
    ['required_policy_amount', { type: 'number', format: 'currency' }],
    ['monthly_cost_of_insurance', { type: 'number', format: 'currency' }],
    ['lease_contract_date', { type: 'date', format: 'date' }],
    ['animals_approved', { type: 'boolean', format: 'boolean' }],
    ['animal_deposit', { type: 'number', format: 'currency' }],
    ['additional_fees', { type: 'number', format: 'currency' }],
    ['pet_1_type', { type: 'string', format: 'string' }],
    ['pet_1_breed', { type: 'string', format: 'string' }],
    ['pet_1_weight', { type: 'number', format: 'number' }],
    ['pet_1_age', { type: 'number', format: 'number' }],
    ['pet_1_house_broken', { type: 'boolean', format: 'boolean' }],
    ['pet_2_type', { type: 'string', format: 'string' }],
    ['pet_2_breed', { type: 'string', format: 'string' }],
    ['pet_2_weight', { type: 'number', format: 'number' }],
    ['pet_2_age', { type: 'number', format: 'number' }],
    ['pet_2_house_broken', { type: 'boolean', format: 'boolean' }],
    ['pet_3_type', { type: 'string', format: 'string' }],
    ['pet_3_breed', { type: 'string', format: 'string' }],
    ['pet_3_weight', { type: 'number', format: 'number' }],
    ['pet_3_age', { type: 'number', format: 'number' }],
    ['pet_3_house_broken', { type: 'boolean', format: 'boolean' }],
])

export const getJobStatus = (ticketStatus: PipelineJobStatus) => {
    if (ticketStatus === 1) {
        return 'Pending'
    } else if (ticketStatus === 2) {
        return 'In Progress'
    } else if (ticketStatus === 3) {
        return 'Completed'
    } else if (ticketStatus === 4) {
        return 'Failed'
    }
    return 'Unknown'
}

export const getJobStage = (stage: PipelineJobStage) => {
    if (stage === 1) {
        return 'Initial'
    } else if (stage === 2) {
        return 'Create Pages'
    } else if (stage === 3) {
        return 'Generate Markdown'
    } else if (stage === 4) {
        return 'Classify Pages'
    } else if (stage === 5) {
        return 'Extract Data'
    } else if (stage === 6) {
        return 'Group By Term'
    }
    return 'Unknown'
}
