import React, { useState } from 'react'

import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Theme,
    IconButton,
    makeStyles,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    ListItemSecondaryAction,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { Container } from '../../components'
import { MoveOutRule } from '../../models'
import { CreateMoveOutRuleRequest } from '../../hooks/useMoveOutRules'

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    },
    listItem: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        width: '100%',
    },
    createForm: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    nameField: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
    },
    weekendCheckbox: {
        marginRight: theme.spacing(2),
    },
    createButton: {
        whiteSpace: 'nowrap',
    },
    ruleName: {
        fontWeight: 'bold',
    },
    ruleAllowWeekends: {
        color: theme.palette.text.secondary,
    },
    ruleList: {
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(2),
        width: '100%',
    },
    list: {
        width: '100%',
    },
    ruleDetails: {
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
    },
}))

interface Props {
    moveOutRuleList: MoveOutRule[]
    theme: Theme
    createMoveOutRule: (
        request: CreateMoveOutRuleRequest,
    ) => Promise<MoveOutRule | null>
    onRuleClick: (rule: MoveOutRule) => void
    deleteMoveOutRule: (moveOutRule: MoveOutRule) => Promise<void>
}

export const MoveOutRuleList = (props: Props) => {
    const {
        moveOutRuleList,
        theme,
        createMoveOutRule,
        onRuleClick,
        deleteMoveOutRule,
    } = props

    const classes = useStyles()

    const [newRuleName, setNewRuleName] = useState('')
    const [allowWeekends, setAllowWeekends] = useState(false)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [ruleToDelete, setRuleToDelete] = useState<MoveOutRule | null>(null)

    const handleCreateRule = async () => {
        if (newRuleName.trim()) {
            await createMoveOutRule({
                name: newRuleName.trim(),
                allow_weekends: allowWeekends,
            })
            setNewRuleName('')
            setAllowWeekends(false)
        }
    }

    const handleDeleteClick = (e: React.MouseEvent, rule: MoveOutRule) => {
        e.stopPropagation()
        setRuleToDelete(rule)
        setDeleteDialogOpen(true)
    }

    const handleDeleteConfirm = async () => {
        if (ruleToDelete) {
            await deleteMoveOutRule(ruleToDelete)
            setDeleteDialogOpen(false)
            setRuleToDelete(null)
        }
    }

    return (
        <Container
            style={{ flex: 1, flexDirection: 'column', overflow: 'auto' }}
        >
            <Container
                className={classes.ruleList}
                style={{ padding: theme.spacing(1) }}
            >
                {moveOutRuleList.length === 0 ? (
                    <Typography>No move out rules found.</Typography>
                ) : (
                    <List className={classes.list}>
                        {moveOutRuleList.map((rule) => (
                            <ListItem
                                key={rule.id}
                                className={classes.listItem}
                                onClick={() => onRuleClick(rule)}
                                button
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.ruleName}
                                        >
                                            {rule.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            component="span"
                                            className={classes.ruleDetails}
                                        >
                                            {`Allow work on weekends: ${
                                                rule.allow_weekends
                                                    ? 'Yes'
                                                    : 'No'
                                            }`}
                                        </Typography>
                                    }
                                />
                                <Container
                                    style={{ marginRight: theme.spacing(5) }}
                                >
                                    <Typography
                                        component="span"
                                        className={classes.ruleDetails}
                                    >
                                        {`${rule.steps.length} step${
                                            rule.steps.length !== 1 ? 's' : ''
                                        }`}
                                    </Typography>
                                </Container>
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onRuleClick(rule)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        onClick={(e) =>
                                            handleDeleteClick(e, rule)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Container>
            <Container
                className={classes.createForm}
                style={{ padding: theme.spacing(1) }}
            >
                <TextField
                    className={classes.nameField}
                    label="Create Rule"
                    value={newRuleName}
                    onChange={(e) => setNewRuleName(e.target.value)}
                    size="small"
                    variant="outlined"
                />
                <FormControlLabel
                    className={classes.weekendCheckbox}
                    control={
                        <Checkbox
                            checked={allowWeekends}
                            onChange={(e) => setAllowWeekends(e.target.checked)}
                            color="primary"
                            size="small"
                        />
                    }
                    label="Allow Weekends"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateRule}
                    disabled={!newRuleName.trim()}
                    className={classes.createButton}
                    size="small"
                >
                    Create Rule
                </Button>
            </Container>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the rule{' '}
                        {ruleToDelete?.name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
