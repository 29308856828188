import React, { useEffect, useState, useMemo } from 'react'
import { LeaseAuditController } from '../../hooks/documentAudit/useLeaseAudit'
import {
    Theme,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    Tooltip,
    TableContainer,
    MenuItem,
    Select,
    makeStyles,
    CircularProgress,
} from '@material-ui/core'
import { Container, UploadProgress } from '../../components'
import { getEntityClassificationUiName } from '../../models/DocumentAudit/services'
import { DropFiles } from '../../components/DropFiles'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { PopoverState, usePopover } from '../../hooks/usePopover'

import {
    EntityValue,
    EntityClassification,
    ResidentTermDirectoryDetail,
    orderedClassifications,
} from '../../models'
import { FilterPopover, FilterState } from './Filter'

const useStyles = makeStyles((theme: Theme) => ({
    tooltipContent: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px',
        backgroundColor: '#333333',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },
    tooltipRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    valueType: {
        fontWeight: 'bold',
        marginRight: theme.spacing(1),
        color: 'white',
    },
    value: {
        textAlign: 'right',
        color: 'white',
    },
    customTooltip: {
        fontSize: 14,
    },
}))

const formatValueType = (valueType: string) => {
    switch (valueType) {
        case 'RENT_ROLL':
            return 'Rent Roll'
        case 'RENT_ROLL_SCHEDULED':
            return 'Scheduled'
        case 'RENT_ROLL_POSTED':
            return 'Posted'
        case 'LEASE':
            return 'Lease'
        case 'RENT_ROLL_ACTUAL':
            return 'Actual'
        default:
            return valueType
    }
}

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

export const RentRollList = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props
    const [showActual, setShowActual] = useState(false)

    const leaseAudit = leaseAuditController.leaseAudit

    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)

    const documentOccurances =
        leaseAuditController.rentRollDocumentOccurances?.results || []

    const { headers, hasActual, hasPosted, tableData } = useMemo(() => {
        const headers = new Set<string>()
        let hasActual = false
        let hasPosted = false
        const tableData: Record<string, any>[] = []

        documentOccurances.forEach((doc) => {
            const rowData: Record<string, any> = {}
            doc.document.entity_values.forEach((ev) => {
                headers.add(ev.classification)
                if (ev.value_type === 'RENT_ROLL_ACTUAL') hasActual = true
                if (ev.value_type === 'RENT_ROLL_POSTED') hasPosted = true

                if (!rowData[ev.classification]) {
                    rowData[ev.classification] = {}
                }
                rowData[ev.classification][ev.value_type] = ev.value
            })
            tableData.push(rowData)
        })

        return {
            headers: Array.from(headers),
            hasActual,
            hasPosted,
            tableData,
        }
    }, [documentOccurances, leaseAudit])

    const renderCellValue = (cellData: Record<string, any>) => {
        const scheduled =
            cellData['RENT_ROLL_SCHEDULED'] || cellData['RENT_ROLL']
        const actual =
            cellData['RENT_ROLL_ACTUAL'] || cellData['RENT_ROLL_POSTED']
        const displayValue = showActual ? actual : scheduled

        if (actual && scheduled) {
            return (
                <Tooltip title={`Scheduled: ${scheduled}, Actual: ${actual}`}>
                    <span>{displayValue}</span>
                </Tooltip>
            )
        }
        return displayValue
    }

    useEffect(() => {
        if (isActive && leaseAudit) {
            leaseAuditController.fetchRentRollDocumentOccurances(leaseAudit.id)
        }
    }, [isActive, leaseAudit])

    const totalRows =
        leaseAuditController.rentRollDocumentOccurances?.count || 0

    if (leaseAudit === undefined || leaseAudit === null) {
        return null
    }

    return (
        <Container
            style={{
                height: height,
                flex: 1,
                flexDirection: 'column',
            }}
        >
            {/* header */}
            <Container
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: theme.spacing(2),
                }}
            >
                <span>Scheduled</span>
                <Switch
                    checked={showActual}
                    onChange={() => setShowActual(!showActual)}
                    color="primary"
                />
                <span>{hasActual ? 'Actual' : 'Posted'}</span>
            </Container>

            {/* table */}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                    key={header}
                                >
                                    {getEntityClassificationUiName(header)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {leaseAuditController.loading
                        .fetchRentRollDocumentOccurances ? (
                        <CircularProgress />
                    ) : (
                        <TableBody>
                            {tableData.map((row, index) => (
                                <TableRow key={index}>
                                    {headers.map((header) => (
                                        <TableCell key={`${index}-${header}`}>
                                            {renderCellValue(row[header] || {})}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            {/* footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    marginTop: theme.spacing(2),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        page={offset / limit + 1}
                        onChange={(event, value) => {
                            const newOffset = (value - 1) * limit
                            setOffset(newOffset)
                            leaseAuditController.fetchRentRollDocumentOccurances(
                                leaseAudit.id,
                                limit,
                                newOffset,
                            )
                        }}
                        count={Math.ceil(totalRows / limit)}
                    />

                    <Container
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            {offset + 1} - {Math.min(offset + limit, totalRows)}{' '}
                            of {totalRows}
                        </span>
                        <Select
                            value={limit}
                            onChange={(event) => {
                                const newLimit = event.target.value as number
                                const newOffset = 0
                                setLimit(newLimit)
                                setOffset(newOffset)
                                leaseAuditController.fetchRentRollDocumentOccurances(
                                    leaseAudit.id,
                                    newLimit,
                                    newOffset,
                                )
                            }}
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            <MenuItem value={10}>10 rows / page</MenuItem>
                            <MenuItem value={25}>25 rows / page</MenuItem>
                            <MenuItem value={50}>50 rows / page</MenuItem>
                        </Select>
                    </Container>
                </Container>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <DropFiles
                        onDrop={(files) => {
                            if (leaseAuditController.leaseAudit) {
                                leaseAuditController.uploadLeaseContractBatch(
                                    files,
                                    leaseAuditController.leaseAudit.id,
                                )
                            }
                        }}
                        displayString="Drop leases here to upload"
                    />
                </Container>
            </Container>

            <UploadProgress
                theme={theme}
                uploadBatch={leaseAuditController.uploadBatch}
                currentFileIndex={leaseAuditController.currentFileIndex}
            />
        </Container>
    )
}
