import React, { useEffect, useState } from 'react'
import styles from './LeaseAudit.module.css'

import FilterListIcon from '@material-ui/icons/FilterList'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'

import {
    LeaseAuditController,
    ResidentTermDirectoryFilters,
    useCriteriaGroup,
} from '../../../hooks/documentAudit'

import {
    Theme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Select,
    MenuItem,
    CircularProgress,
    Popover,
    TextField,
    Tooltip,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { Container, Selector, UploadProgress } from '../../../components'
import { DropFiles } from '../../../components/DropFiles'
import {
    Autocomplete,
    Pagination,
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab'
import { PopoverState, usePopover } from '../../../hooks/usePopover'

import {
    EntityValue,
    EntityClassification,
    ResidentTermDirectoryDetail,
    getEntityClassificationUiName,
    orderedClassifications,
    RentRollAccountingMode,
} from '../../../models'

import {
    CriteriaGroup,
    DocumentFilter,
    MatchType,
} from '../../../models/DocumentAudit/types'
import { FilterPopover, FilterState } from '../Filter'
import { ResidentDirectoryDetailModal } from '../ResidentDirectoryDetailModal'
import { CriteriaGroupModal } from '../CriteriaGroupModal/CriteriaGroupModal'
import { TableTab } from './TableTab'
import { UnpairedTab } from './UnpairedTab'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

export const AuditPage = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props

    const criteriaGroupController = useCriteriaGroup()
    const {
        criteriaGroups,
        createCriteriaGroup,
        updateCriteriaGroup,
        fetchCriteriaGroups,
    } = criteriaGroupController
    const [criteriaGroupModalOpen, setCriteriaGroupModalOpen] = useState({
        open: false,
        groupId: -1,
    })

    const [activeTab, setActiveTab] = useState<'paired' | 'unpaired' | 'all'>(
        'paired',
    )

    const [
        selectedCriteriaGroupId,
        setSelectedCriteriaGroupId,
    ] = useState<number>(-1)

    useEffect(() => {
        fetchCriteriaGroups().then(() => {
            setSelectedCriteriaGroupId(
                leaseAuditController.leaseAudit?.criteria_group || -1,
            )
        })
    }, [leaseAuditController.leaseAudit])

    return (
        <Container
            style={{
                height: height,
                flex: 1,
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            {/* Header */}
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    alignItems: 'center',
                    padding: theme.spacing(2),
                    height: 100,
                }}
            >
                <ToggleButtonGroup
                    exclusive
                    value={activeTab}
                    onChange={(_, value) => {
                        if (value === null) {
                            return
                        }

                        setActiveTab(value)
                    }}
                >
                    <ToggleButton value="paired">Paired</ToggleButton>
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="unpaired">Unpaired</ToggleButton>
                </ToggleButtonGroup>

                <div style={{ flex: 1 }} />
                <Container>
                    <Selector<CriteriaGroup>
                        customStyle={{
                            formControl: { width: 300 },
                            menuItem: {
                                alignItems: 'center',
                                display: 'flex',
                            },
                        }}
                        currentValue={selectedCriteriaGroupId}
                        onChange={(e) => {
                            const value = Number(e.target.value)
                            setSelectedCriteriaGroupId(value)
                            if (leaseAuditController.leaseAudit) {
                                leaseAuditController.setCriteriaGroup(
                                    value,
                                    leaseAuditController.leaseAudit.id,
                                )
                            }
                        }}
                        data={criteriaGroups}
                        getDisplayString={(c) => c.name}
                        label="Match Criteria"
                        onClickAdd={() => {
                            setCriteriaGroupModalOpen({
                                open: true,
                                groupId: -1,
                            })
                        }}
                        ornamentPosition="end"
                        getOrnament={(selectedCriteriaGroup) => (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCriteriaGroupModalOpen({
                                        open: true,
                                        groupId: selectedCriteriaGroup.id,
                                    })
                                }}
                            >
                                <SettingsApplicationsIcon />
                            </IconButton>
                        )}
                    />
                </Container>
            </Container>
            {/* Body */}
            {activeTab === 'paired' && (
                <TableTab
                    theme={theme}
                    height={height - 100}
                    leaseAuditController={leaseAuditController}
                    criteriaGroupController={criteriaGroupController}
                    mode="paired"
                />
            )}
            {activeTab === 'all' && (
                <TableTab
                    theme={theme}
                    height={height - 100}
                    leaseAuditController={leaseAuditController}
                    criteriaGroupController={criteriaGroupController}
                    mode="all"
                />
            )}
            {activeTab === 'unpaired' && (
                <UnpairedTab
                    theme={theme}
                    height={height - 100}
                    leaseAuditController={leaseAuditController}
                />
            )}
            {/* {activeTab === 'paired' ? (
                <TableTab
                    theme={theme}
                    height={height - 100}
                    leaseAuditController={leaseAuditController}
                    criteriaGroupController={criteriaGroupController}
                    mode="paired"
                />
            ) : (
                <UnpairedTab
                    theme={theme}
                    height={height - 100}
                    leaseAuditController={leaseAuditController}
                />
            )} */}
            <CriteriaGroupModal
                theme={theme}
                openState={criteriaGroupModalOpen}
                onClose={() =>
                    setCriteriaGroupModalOpen({ open: false, groupId: -1 })
                }
                onSave={(name, criteriaState) => {
                    setCriteriaGroupModalOpen({ open: false, groupId: -1 })
                    const criteria: {
                        entity_classification: EntityClassification
                        match_type: MatchType
                    }[] = []
                    Object.keys(criteriaState).forEach((key) => {
                        const entityClassification = key as EntityClassification
                        const matchType = criteriaState[entityClassification]

                        criteria.push({
                            entity_classification: entityClassification,
                            match_type: matchType,
                        })
                    })

                    if (criteriaGroupModalOpen.groupId === -1) {
                        createCriteriaGroup(name, criteria)
                    } else {
                        updateCriteriaGroup(
                            criteriaGroupModalOpen.groupId,
                            name,
                            criteria,
                        )
                    }
                }}
                criteriaGroups={criteriaGroups}
            />
        </Container>
    )
}

type MatchTypeMap = Map<EntityClassification, MatchType>

type ModeDetail = {
    mode: string
    modeCount: number
    concensus: boolean
}

type DocumentTypeValues = {
    entityValues: EntityValue[]
    modeDetail: ModeDetail | null
}

export type DirectoryEntityValueMap = Map<
    EntityClassification,
    {
        leaseInformation: DocumentTypeValues
        rentRollInformation: DocumentTypeValues
    }
>
