import React, { useEffect, useState } from 'react'

import { ContainerRoot } from '../ContainerRoot'
import { Tab, Tabs, useTheme } from '@material-ui/core'
import { MoveInspectionContainer } from '../DamageHome/MoveInspectionContainer'
import { useLeaseFilter } from '../../hooks/useLeaseFilter'
import { LeaseFilterDrawer } from './Forms/LeaseFilterDrawer'
import { useLease, useRootInfrastructure } from '../../hooks'
import { Lease, LeaseStatusType } from '../../models'
import { Container, SideDrawerContainer } from '../../components'
import { axiosInstance } from '../../helpers'
import { LeaseHomeLeasesTab } from './Tabs/LeaseHome/LeaseHomeLeasesTab'

const LEASES_TAB = 0
const MOVE_IN_TAB = 1

export const LeaseHome = () => {
    const [lastSyncDate, setLastSyncDate] = useState<Date | null>(null)

    const {
        dateFilter,
        setDateFilter,
        locationFinderSelection,
        filterDrawerOpen,
        searchValue,
        setSearchValue,
        setFilterDrawerOpen,
        getFilteredLeases,
        statusFilter,
        setStatusFilter,
        setIssueFilter,
        issueFilter,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        autoCompleteFilter,
        setAutoCompleteFilter,
    } = useLeaseFilter()

    const {
        leaseList,
        getLeaseList,
        transitionLease,
        isLoading,
        setLeaseList,
        updateLease,
    } = useLease()

    const {
        leaseList: childLeaseList,
        getLeaseList: getChildLeaseList,
    } = useLease()

    const getLastSyncDate = () => {
        axiosInstance
            .get(`stats/reports/`, {
                params: { report_types: 'LEASE_SYNC_REPORT' },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    const lastReport = res.data[res.data.length - 1]
                    const creationTimeStr = lastReport.creation_time
                    const lastSyncDate = new Date(creationTimeStr)
                    setLastSyncDate(lastSyncDate)
                }
            })
    }

    // Load the leases
    useEffect(() => {
        let statusStr = ''
        Object.keys(statusFilter).forEach((key) => {
            const status: LeaseStatusType = Number(key) as LeaseStatusType
            if (statusFilter[status]) {
                statusStr += `${status},`
            }
        })

        if (statusStr === '') {
            statusStr = '1,2,3,4,5,6'
        }

        const parentPromise = getLeaseList({
            params: {
                statuses: statusStr,
                home: true,
                is_child: false,
            },
        })
        const childPromise = getChildLeaseList({
            params: {
                statuses: statusStr,
                home: true,
                is_child: true,
            },
        })

        Promise.all([childPromise, parentPromise]).then((promiseRes) => {
            const childLeases = promiseRes[0]
            const parentLeases = promiseRes[1]
            let childIDX = 0

            const newParentLeases = parentLeases.map((lease) => {
                const innerChildren: Lease[] = []
                while (
                    childIDX < childLeases.length &&
                    childLeases[childIDX].parent === lease.id
                ) {
                    innerChildren.push(childLeases[childIDX])
                    childIDX++
                }
                return { ...lease, children: innerChildren }
            })

            setLeaseList(newParentLeases)
        })
    }, [statusFilter])

    // Load the last sync date
    useEffect(() => {
        getLastSyncDate()
    }, [])

    const { tree, unitCount } = useRootInfrastructure(true)

    const [tab, setTab] = useState(LEASES_TAB)
    const theme = useTheme()

    return (
        <ContainerRoot style={{}}>
            <SideDrawerContainer open={filterDrawerOpen}>
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Tabs
                        style={{
                            backgroundColor: theme.palette.grey[200],
                            margin: theme.spacing(1),
                        }}
                        value={tab}
                        onChange={(_, v) => {
                            setSearchValue('')
                            setTab(v)
                        }}
                    >
                        <Tab label="Leases" value={LEASES_TAB} />
                        <Tab label="Move In Inspections" value={MOVE_IN_TAB} />
                    </Tabs>
                    {tab === LEASES_TAB && (
                        <LeaseHomeLeasesTab
                            getFilteredLeases={getFilteredLeases}
                            statusFilter={statusFilter}
                            filterDrawerOpen={filterDrawerOpen}
                            setFilterDrawerOpen={setFilterDrawerOpen}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            locationFinderSelection={locationFinderSelection}
                            setStatusFilter={setStatusFilter}
                            tree={tree}
                            unitCount={unitCount}
                            leaseList={leaseList}
                            setLeaseList={setLeaseList}
                            isLoading={isLoading}
                            transitionLease={transitionLease}
                            hasIssueFilter={issueFilter}
                            setHasIssue={setIssueFilter}
                            tenantInspectionStatusFilter={
                                tenantInspectionStatusFilter
                            }
                            setTenantInspectionStatusFilter={
                                setTenantInspectionStatusFilter
                            }
                            updateLease={updateLease}
                            autoCompleteFilter={autoCompleteFilter}
                            setAutoCompleteFilter={setAutoCompleteFilter}
                            lastSyncDate={lastSyncDate}
                        />
                    )}
                    {tab === MOVE_IN_TAB && (
                        <MoveInspectionContainer
                            getFilteredLeases={getFilteredLeases}
                            filterDrawerOpen={filterDrawerOpen}
                            setFilterDrawerOpen={setFilterDrawerOpen}
                            setSearchValue={setSearchValue}
                            tree={tree}
                            leaseList={leaseList}
                            isLoading={isLoading}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            locationFinderSelection={locationFinderSelection}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                            hasIssue={issueFilter}
                            setHasIssue={setIssueFilter}
                            tenantInspectionStatusFilter={
                                tenantInspectionStatusFilter
                            }
                            setTenantInspectionStatusFilter={
                                setTenantInspectionStatusFilter
                            }
                            searchValue={searchValue}
                            autoCompleteFilter={autoCompleteFilter}
                            setAutoCompleteFilter={setAutoCompleteFilter}
                        />
                    )}
                </Container>
            </SideDrawerContainer>

            <LeaseFilterDrawer
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                root={tree.root}
                dateFilter={dateFilter}
                onChangeDateFilter={setDateFilter}
                locationFinderSelection={locationFinderSelection}
                hasIssueFilter={issueFilter}
                setHasIssueFilter={setIssueFilter}
                tenantInspectionStatusFilter={tenantInspectionStatusFilter}
                setTenantInspectionStatusFilter={
                    setTenantInspectionStatusFilter
                }
                autoCompleteFilter={autoCompleteFilter}
                setAutoCompleteFilter={setAutoCompleteFilter}
            />
        </ContainerRoot>
    )
}
