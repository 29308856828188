import React, { useEffect, useState } from 'react'
import {
    Theme,
    Modal,
    Paper,
    Slide,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@material-ui/core'
import {
    EntityClassification,
    EntityValue,
} from '../../models/DocumentAudit/types'
import { Container } from '../../components'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ListAltIcon from '@material-ui/icons/ListAlt'
import {
    getEntityClassificationUiName,
    getEntityValueTypeUiName,
} from '../../models/DocumentAudit/services'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DirectoryEntityValueMap } from './AuditPage/AuditPage'

interface Props {
    theme: Theme
    height: number
    open: boolean
    onClose: () => void
    entityValueMap: DirectoryEntityValueMap | null
    classifications: EntityClassification[]
    selectedClassification: EntityClassification | null
    selectedEntityValue: EntityValue | null
    setSelectedEntityValue: (entityValue: EntityValue | null) => void
}

export const ResidentDirectoryDetailModal = (props: Props) => {
    const {
        theme,
        height,
        onClose,
        entityValueMap,
        classifications,
        open,
        selectedClassification,
        setSelectedEntityValue,
        selectedEntityValue,
    } = props

    return (
        <Modal
            open={open}
            onClose={() => {
                setSelectedEntityValue(null)
                onClose()
            }}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: MODAL_H,
                            width: MODAL_W,
                            display: 'flex',
                            overflow: 'hidden',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            flexDirection: 'row',
                            flex: 1,
                        }}
                    >
                        {/* Left Column, Entity Values */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                overflowY: 'auto',
                            }}
                        >
                            {classifications.map((c, index) => {
                                const leaseValues =
                                    entityValueMap?.get(c)?.leaseInformation
                                        .entityValues || []
                                const rrValues =
                                    entityValueMap?.get(c)?.rentRollInformation
                                        .entityValues || []

                                return (
                                    <ClassificationCard
                                        key={index}
                                        classification={c}
                                        selectedEntityValue={
                                            selectedEntityValue
                                        }
                                        setSelectedEntityValue={
                                            setSelectedEntityValue
                                        }
                                        entityValues={[
                                            ...leaseValues,
                                            ...rrValues,
                                        ]}
                                        theme={theme}
                                        isSelected={
                                            c === selectedClassification
                                        }
                                    />
                                )
                            })}
                        </Container>

                        {/* Right Column, Document */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                padding: theme.spacing(1),
                                borderLeft: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            {selectedEntityValue ? (
                                selectedEntityValue.value_type === 'LEASE' ? (
                                    <iframe
                                        key={`PDF-${selectedEntityValue.id}`}
                                        id="pdf-viewer"
                                        width="100%"
                                        height="100%"
                                        loading="lazy"
                                        src={`${selectedEntityValue.document.file}#page=${selectedEntityValue.page_number}&view=FitW&navpanes=0`}
                                    />
                                ) : (
                                    <iframe
                                        key={`Excel-${selectedEntityValue.id}`}
                                        id="excel-viewer"
                                        width="100%"
                                        height="100%"
                                        loading="lazy"
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                                            selectedEntityValue.document.file,
                                        )}
                                        &activeCell=A${
                                            selectedEntityValue.page_number
                                        }`}
                                    />
                                )
                            ) : (
                                <span>No document selected</span>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface ClassificationCardProps {
    classification: EntityClassification
    selectedEntityValue: EntityValue | null
    setSelectedEntityValue: (entityValue: EntityValue) => void
    entityValues: EntityValue[]
    theme: Theme
    isSelected: boolean
}

const ClassificationCard: React.FC<ClassificationCardProps> = (
    props: ClassificationCardProps,
) => {
    const {
        classification,
        selectedEntityValue,
        setSelectedEntityValue,
        entityValues,
        theme,
        isSelected,
    } = props

    const [isExpanded, setIsExpanded] = useState(isSelected)

    useEffect(() => {
        setIsExpanded(isSelected)
        // If this classification is selected and there's no selected entity value,
        // select the first entity value of this classification
        if (isSelected && !selectedEntityValue && entityValues.length > 0) {
            setSelectedEntityValue(entityValues[0])
        }
    }, [isSelected, selectedEntityValue, entityValues])

    const handleChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setIsExpanded(expanded)
    }

    return (
        <Accordion expanded={isExpanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${classification}-content`}
                id={`panel-${classification}-header`}
                style={{
                    backgroundColor:
                        selectedEntityValue?.classification === classification
                            ? theme.palette.primary.light
                            : 'transparent',
                }}
            >
                <Typography>
                    {getEntityClassificationUiName(classification)}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(1),
                }}
            >
                {entityValues.map((ev) =>
                    ev.value_type === 'LEASE' ? (
                        <LeaseCard
                            key={ev.id}
                            entityValue={ev}
                            theme={theme}
                            onClick={setSelectedEntityValue}
                            isSelected={selectedEntityValue?.id === ev.id}
                        />
                    ) : (
                        <RentRollCard
                            key={ev.id}
                            entityValue={ev}
                            theme={theme}
                            onClick={setSelectedEntityValue}
                            isSelected={selectedEntityValue?.id === ev.id}
                        />
                    ),
                )}
            </AccordionDetails>
        </Accordion>
    )
}

interface CardProps {
    entityValue: EntityValue
    theme: Theme
    onClick: (entityValue: EntityValue) => void
    isSelected: boolean
}

const RentRollCard = (props: CardProps) => {
    const { entityValue, theme, onClick, isSelected } = props
    return (
        <Container
            style={{
                cursor: 'pointer',
                padding: theme.spacing(1),
                borderBottom: `1px solid ${theme.palette.divider}`,
                flexDirection: 'column',
                backgroundColor: isSelected
                    ? theme.palette.action.selected
                    : 'transparent',
            }}
            onClick={() => onClick(entityValue)}
        >
            <Container style={{ flexDirection: 'row' }}>
                <ListAltIcon />
                <span
                    style={{
                        marginLeft: theme.spacing(1),
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    {getEntityValueTypeUiName(entityValue.value_type)}
                </span>
            </Container>
            <Container
                style={{ flexDirection: 'row', marginTop: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.body2,
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightBold,
                        flex: 1,
                    }}
                >
                    {entityValue.value}
                </span>
            </Container>
        </Container>
    )
}

const LeaseCard = (props: CardProps) => {
    const { entityValue, theme, onClick, isSelected } = props
    return (
        <Container
            style={{
                cursor: 'pointer',
                padding: theme.spacing(1),
                borderBottom: `1px solid ${theme.palette.divider}`,
                flexDirection: 'column',
                backgroundColor: isSelected
                    ? theme.palette.action.selected
                    : 'transparent',
            }}
            onClick={() => onClick(entityValue)}
        >
            <Container style={{ flexDirection: 'row' }}>
                <PictureAsPdfIcon />
                <span
                    style={{
                        marginLeft: theme.spacing(1),
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    {entityValue.classified_section?.section_type.name}
                </span>
            </Container>
            <Container
                style={{ flexDirection: 'row', marginTop: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.body2,
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightBold,
                        flex: 1,
                    }}
                >
                    {entityValue.value}
                </span>
                <span
                    style={{
                        ...theme.typography.body2,
                        color: theme.palette.text.secondary,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    page: {entityValue.page_number}
                </span>
            </Container>
        </Container>
    )
}

const MODAL_H = window.innerHeight * 0.9
const MODAL_W = window.innerWidth * 0.75

// Remove or comment out the isExcelFile function for now
