export const Routes = {
    home: '/home',
    apartmentSchedule: '/schedule',
    apartmentScheduleDetail: '/schedule/:id',
    apartmentScheduleArchivedDetail: '/schedule/:id/archive',
    workforce: '/workforce',
    nvl: '/national-vendor-list',
    nvlHomePage: '/nvl-home-page',
    inspect: '/inspect',
    inspectionDetail: '/inspect/:id',
    actionItem: '/action-item',
    ezchat: '/ez-chat',
    invoiceHome: '/invoice-home',
    invoiceList: '/invoice/company/:id',
    vendorAnalytics: '/analytics/:id/',
    analytics: '/analytics',
    infrastructureManager: '/infrastructure-manager',
    inventoryConf: '/inventory-config',
    areaConf: '/area-config',
    unitConf: '/unit-config',
    damageConf: '/damage-config',
    scheduleLocation: '/scheduler/:id/location',
    vendorSchedule: '/scheduler',
    vendorScheduleDetail: '/scheduler/:id/location/:locationId',
    login: '/login',
    permissionDenied: '/denied',
    statusGroups: '/status-groups',
    inspectionType: '/inspection-type',
    leases: '/leases',
    help: '/help',
    changeOrderConfig: '/change-order-config',
    infrastructureConfig: '/infrastructure-configure',
    reports: '/reports',
    //New Routes
    dashboard: '/dashboard',
    scheduler: '/schedule',
    actionItems: '/action-item',
    nationalVendorList: '/national-vendor-list',
    workForce: '/workforce',
    chat: '/ez-chat',
    analyticsNew: '/analytics',
    infraStructureManager: '/infrastructure-manager',
    settings: '/settings',
    helpNew: '/help',
    vendorSettings: '/vendor-settings',
    vendorHelp: '/vendor-help',
    ezpay: '/ez-pay',
    ezwork: '/ezwork',
    portfolioAnalytics: '/portfolio-analytics',
    nationalPropertyList: '/national-property-list',
    leaseAudit: '/lease-audit',
    leaseAuditDetail: '/lease-audit/:id',
    jobBoard: '/job-board',
}
