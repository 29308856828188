import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { Container } from '../../components'
import { useParams } from 'react-router-dom'
import { Tab, Tabs, useTheme } from '@material-ui/core'
import { LeasePdfList } from './LeasePdfList'

import { useLeaseAudit } from '../../hooks/documentAudit'
import { AuditPage } from './AuditPage/AuditPage'
import { HistoryPage } from './HistoryPage'
import { AnalysisPage } from './AnalysisPage'
import { RentRollList } from './RentRollList'
import { isAccountManager } from '../../helpers'
import { useUser } from '../../hooks'

export const LeaseAuditRoot = () => {
    const { id } = useParams<{ id?: string }>()

    const [tab, setTab] = useState<number>(0)

    const { workspaceUser } = useUser()

    const theme = useTheme()

    const leaseAuditController = useLeaseAudit()
    const superUser = isAccountManager(workspaceUser)

    useEffect(() => {
        if (id) {
            const numId = Number(id)
            leaseAuditController.fetchLeaseAudit(numId)
        }
    }, [id])

    const tabs = [
        <Tab key={0} label="Audit" />,
        <Tab key={1} label="Analysis" />,
    ]

    const carouselItems = [
        <AuditPage
            key={0}
            theme={theme}
            isActive={tab === 0}
            height={bodyH}
            leaseAuditController={leaseAuditController}
        />,
        <AnalysisPage
            key={1}
            theme={theme}
            height={bodyH}
            leaseAuditController={leaseAuditController}
            isActive={tab === 1}
        />,
    ]

    if (superUser) {
        carouselItems.push(
            <LeasePdfList
                key={2}
                isActive={tab === 2}
                theme={theme}
                height={bodyH}
                leaseAuditController={leaseAuditController}
            />,
        )
        carouselItems.push(
            <RentRollList
                key={3}
                theme={theme}
                height={bodyH}
                isActive={tab === 3}
                leaseAuditController={leaseAuditController}
            />,
        )
        carouselItems.push(
            <HistoryPage
                key={4}
                isActive={tab === 4}
                theme={theme}
                height={bodyH}
                documentBatches={leaseAuditController.documentBatches}
                leaseAuditController={leaseAuditController}
            />,
        )

        tabs.push(
            <Tab key={2} label="Lease" />,
            <Tab key={3} label="Rent Roll" />,
            <Tab key={4} label="History" />,
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                height: 'calc(100vh - 104px)',
                flexDirection: 'column',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: headerH,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    {tabs}
                </Tabs>

                <div style={{ flex: 1 }} />
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    {leaseAuditController.leaseAudit?.name}
                </span>
            </Container>

            {/* Body */}
            <Carousel
                swipeable={false}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={tab}
            >
                {carouselItems}
            </Carousel>

            {/* Footer */}
        </Container>
    )
}

const containerH = window.innerHeight - 104

const headerH = 60
const bodyH = containerH - headerH
