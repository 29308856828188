import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react'

import {
    IconButton,
    makeStyles,
    Paper,
    Typography,
    useTheme,
} from '@material-ui/core'

import {
    ApartmentVendorRuleDict,
    CreateOrUpdateAptVendorRuleRequest,
    DeleteAptVendorRuleRequest,
} from '../../hooks/useApartmentVendorRule'
import { convertListToMap, IdBoolMap, ListVendor, Service } from '../../models'
import { Selector } from '../../components'
import { VendorServiceRuleRow } from './VendorServiceRuleRow'
import { AddVendorServiceRule } from './AddVendorServiceRule'
import { ServiceRulesList } from './ServiceRulesList'
import { ArrowBack } from '@material-ui/icons'

interface Props {
    apartmentVendorRuleDict: ApartmentVendorRuleDict
    serviceList: Service[]
    vendorList: ListVendor[]
    createOrUpdateAptVendorRule: (
        request: CreateOrUpdateAptVendorRuleRequest,
    ) => Promise<ApartmentVendorRuleDict | null>
    deleteAptVendorRule: (
        request: DeleteAptVendorRuleRequest,
    ) => Promise<ApartmentVendorRuleDict | null>
    setApartmentVendorRuleDict: Dispatch<
        SetStateAction<ApartmentVendorRuleDict>
    >
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 200px)',
        overflow: 'hidden',
        width: '700px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
    },
    header: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    rulesList: {
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(2),
    },
    addRuleContainer: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    dragOver: {
        backgroundColor: theme.palette.action.hover,
    },
    pageContainer: {
        flexDirection: 'column',
        height: '100%',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    headerText: {
        flex: 1,
    },
    serviceSelector: {
        margin: theme.spacing(2),
    },
}))

export const VendorServiceRule = (props: Props) => {
    const {
        apartmentVendorRuleDict: apartmentVendorRuleDict,
        serviceList,
        vendorList,
        createOrUpdateAptVendorRule,
        deleteAptVendorRule,
        setApartmentVendorRuleDict,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const [selectedServiceId, setSelectedServiceId] = useState<number | null>(
        null,
    )

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null)
    const [isAddingNewRule, setIsAddingNewRule] = useState(false)

    const vendorMap = convertListToMap(vendorList)

    const validVendors = useMemo(() => {
        const selectedServiceRules =
            apartmentVendorRuleDict[selectedServiceId ?? -1] ?? []
        const selectedVendorMap: IdBoolMap = {}
        selectedServiceRules.forEach((rule) => {
            if (rule.vendor) {
                selectedVendorMap[rule.vendor] = true
            }
        })
        return vendorList.filter((vendor) => {
            return (
                vendor.services.find((srv) => srv.id === selectedServiceId) &&
                !selectedVendorMap[vendor.id]
            )
        })
    }, [serviceList, vendorList, selectedServiceId, apartmentVendorRuleDict])

    const handleDragStart = (index: number) => {
        setDraggedIndex(index)
    }

    const handleDragOver = (
        e: React.DragEvent<HTMLDivElement>,
        index: number,
    ) => {
        e.preventDefault()
        e.currentTarget.classList.add(classes.dragOver)
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.classList.remove(classes.dragOver)
    }

    const handleDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
            e.preventDefault()
            e.currentTarget.classList.remove(classes.dragOver)

            if (
                draggedIndex === null ||
                draggedIndex === dropIndex ||
                selectedServiceId === null
            )
                return

            const newRules = [
                ...(apartmentVendorRuleDict[selectedServiceId] || []),
            ]
            const newRuleStore = [...newRules]
            const [draggedItem] = newRules.splice(draggedIndex, 1)

            // Find the EZNow index
            const ezNowIndex = newRules.findIndex(
                (rule) => rule.vendor === null,
            )

            // Determine the new drop index
            let newDropIndex = dropIndex
            if (ezNowIndex !== -1 && dropIndex >= ezNowIndex) {
                newDropIndex = Math.max(0, ezNowIndex - 1)
            }

            newRules.splice(dropIndex, 0, draggedItem)

            const updatedRules = newRules.map((rule, index) => ({
                ...rule,
                rank: index + 1,
            }))

            // Update the apartmentVendorRuleDict
            const updatedDict = {
                ...apartmentVendorRuleDict,
                [selectedServiceId]: updatedRules,
            }

            setApartmentVendorRuleDict(updatedDict)
            setDraggedIndex(null)

            const request: CreateOrUpdateAptVendorRuleRequest = {
                service_id: selectedServiceId,
                vendor_id: draggedItem.vendor,
                rank: dropIndex + 1,
            }

            createOrUpdateAptVendorRule(request).then((res) => {
                if (res === null) {
                    const updatedDict = {
                        ...apartmentVendorRuleDict,
                        [selectedServiceId]: newRuleStore,
                    }

                    setApartmentVendorRuleDict(updatedDict)
                }
            })
        },
        [draggedIndex, apartmentVendorRuleDict, selectedServiceId],
    )

    const handleSelectService = (serviceId: number) => {
        setSelectedServiceId(serviceId)
        setIsAddingNewRule(false)
    }

    const handleAddNewRule = () => {
        setSelectedServiceId(null)
        setIsAddingNewRule(true)
    }

    const handleBackClick = () => {
        setSelectedServiceId(null)
        setIsAddingNewRule(false)
    }

    if (selectedServiceId === null && !isAddingNewRule) {
        return (
            <Paper className={classes.root}>
                <div className={classes.pageContainer}>
                    <ServiceRulesList
                        apartmentVendorRuleDict={apartmentVendorRuleDict}
                        serviceList={serviceList}
                        onSelectService={handleSelectService}
                        onAddNewRule={handleAddNewRule}
                    />
                </div>
            </Paper>
        )
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerContent}>
                    {(selectedServiceId !== null || isAddingNewRule) && (
                        <IconButton
                            className={classes.backButton}
                            onClick={handleBackClick}
                            aria-label="back to service list"
                        >
                            <ArrowBack />
                        </IconButton>
                    )}
                    <div className={classes.headerText}>
                        <Typography variant="h5" component="h2">
                            Vendor Service Rules
                        </Typography>
                        <Typography variant="body2">
                            {selectedServiceId === null && !isAddingNewRule
                                ? 'Select a service to get started'
                                : isAddingNewRule
                                ? 'Add New Service Rule'
                                : `Rules for ${
                                      serviceList.find(
                                          (s) => s.id === selectedServiceId,
                                      )?.name
                                  }`}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={classes.contentContainer}>
                {isAddingNewRule && (
                    <Selector
                        label="Service"
                        currentValue={selectedServiceId || ''}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedServiceId(event.target.value as number)
                        }}
                        data={serviceList}
                        getDisplayString={(s: Service) => s.name}
                        size="small"
                        customStyle={{
                            formControl: {
                                margin: theme.spacing(2),
                                width: 'calc(100% - 32px)',
                            },
                        }}
                    />
                )}
                {selectedServiceId !== null && (
                    <>
                        <div className={classes.rulesList}>
                            {apartmentVendorRuleDict[selectedServiceId]?.map(
                                (apartmentVendorRule, index) => (
                                    <VendorServiceRuleRow
                                        key={`APARTMENT_VENDOR_RULE_${selectedServiceId}_${apartmentVendorRule.id}`}
                                        apartmentVendorRule={
                                            apartmentVendorRule
                                        }
                                        vendor={
                                            vendorMap[
                                                apartmentVendorRule.vendor ?? -1
                                            ]
                                        }
                                        onClickDelete={() => {
                                            const request: DeleteAptVendorRuleRequest = {
                                                service_id: selectedServiceId,
                                                rule_id: apartmentVendorRule.id,
                                            }
                                            deleteAptVendorRule(request)
                                        }}
                                        theme={theme}
                                        index={index}
                                        onDragStart={() =>
                                            handleDragStart(index)
                                        }
                                        onDragOver={(e) =>
                                            handleDragOver(e, index)
                                        }
                                        onDragLeave={handleDragLeave}
                                        onDrop={(e) => handleDrop(e, index)}
                                    />
                                ),
                            )}
                        </div>
                        <div className={classes.addRuleContainer}>
                            <AddVendorServiceRule
                                vendorList={validVendors}
                                theme={theme}
                                selectedServiceId={selectedServiceId || -1}
                                existingRules={
                                    apartmentVendorRuleDict[
                                        selectedServiceId ?? -1
                                    ] || []
                                }
                                createOrUpdateAptVendorRule={
                                    createOrUpdateAptVendorRule
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </Paper>
    )
}
