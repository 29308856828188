import React, { useState, useEffect, useCallback } from 'react'
import {
    Theme,
    Typography,
    Grid,
    Card,
    CardContent,
    Box,
    IconButton,
    Collapse,
    Container,
    Tooltip,
    CircularProgress,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { PlotData } from 'plotly.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { currencyFormatterNoDecimals } from '../../helpers'
import { LeaseAuditController } from '../../hooks/documentAudit'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'
import { ResidentTermDirectoryDetail } from '../../models/DocumentAudit/types'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

const useStyles = makeStyles((theme) => ({
    largeTooltip: {
        fontSize: '1rem', // Adjust this value as needed
    },
}))

export const AnalysisPage = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props

    const leaseAudit = leaseAuditController.leaseAudit

    const classes = useStyles()

    const SectionCard: React.FC<{
        title: React.ReactNode
        children: React.ReactNode
        expandedInitial: boolean
    }> = ({ title, children, expandedInitial = false }) => {
        const [expanded, setExpanded] = useState(expandedInitial)

        return (
            <Card style={{ marginBottom: theme.spacing(3), width: '100%' }}>
                <CardContent>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5">{title}</Typography>
                        <IconButton onClick={() => setExpanded(!expanded)}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                    <Collapse in={expanded}>{children}</Collapse>
                </CardContent>
            </Card>
        )
    }

    useEffect(() => {
        if (isActive && leaseAudit) {
            leaseAuditController.fetchAnalysisData(leaseAudit.id)
        }
    }, [isActive, leaseAudit])

    return (
        <Container maxWidth={false}>
            <Container
                maxWidth={false}
                style={{
                    padding: theme.spacing(1),
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                {leaseAuditController.loading.fetchAnalysisData ? (
                    <CircularProgress />
                ) : leaseAuditController.analysisData ? (
                    <>
                        <SectionCard
                            title={
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h5">
                                        Financial Summary
                                    </Typography>
                                    <Tooltip
                                        title="Variance = Rent Roll - Lease"
                                        arrow
                                        classes={{
                                            tooltip: classes.largeTooltip,
                                        }}
                                    >
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            expandedInitial={true}
                        >
                            <Grid container spacing={2}>
                                {Object.keys(
                                    leaseAuditController.analysisData.rent_roll,
                                ).map((key) => {
                                    const rentRollValue = leaseAuditController
                                        .analysisData.rent_roll[key] as number
                                    const leaseValue = leaseAuditController
                                        .analysisData.lease[key] as number
                                    const variance = rentRollValue - leaseValue

                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={3}
                                            key={key}
                                        >
                                            <Card>
                                                <CardContent>
                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {key
                                                            .split('_')
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0,
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1,
                                                                    ),
                                                            )
                                                            .join(' ')}
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Rent Roll:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                rentRollValue,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Lease:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                leaseValue,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Variance:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                variance,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </SectionCard>
                    </>
                ) : (
                    <Container>
                        <Typography>No rent roll data available.</Typography>
                    </Container>
                )}
            </Container>
        </Container>
    )
}
