import React, { useState } from 'react'

import { Container } from '../../components'
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    TableContainer,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    DialogContentText,
    CircularProgress,
    useTheme,
} from '@material-ui/core'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { useLeaseAudit } from '../../hooks/documentAudit'
import { useHistory } from 'react-router-dom'
import { SearchField } from '../../components/SearchField'

export const LeaseAuditList = () => {
    const { leaseAudits, createLeaseAudit, loading } = useLeaseAudit()

    const history = useHistory()
    const [searchValue, setSearchValue] = useState('')
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')

    const theme = useTheme()

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container style={{ padding: theme.spacing(1) }}>
                <SearchField
                    placeholder="Search Lease Audits"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />

                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                        setName('')
                        setOpen(true)
                    }}
                    color="primary"
                    disabled={loading.fetchLeaseAudit}
                >
                    Create Lease Audit
                </Button>
            </Container>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaseAudits
                            .filter((audit) =>
                                audit?.name
                                    .toLowerCase()
                                    .includes(searchValue.toLocaleLowerCase()),
                            )
                            .map((audit) => {
                                if (audit === null) {
                                    return null
                                }
                                const createdDate = new Date(audit.created_date)

                                return (
                                    <TableRow key={audit.id}>
                                        <TableCell>{audit.name}</TableCell>
                                        <TableCell>
                                            {audit.created_by?.name}
                                        </TableCell>
                                        <TableCell>
                                            {createdDate.toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() =>
                                                        history.push(
                                                            `/lease-audit/${audit.id}`,
                                                        )
                                                    }
                                                >
                                                    <ArrowRightAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            {(loading.fetchLeaseAudit || loading.createLeaseAudit) && (
                <CircularProgress />
            )}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create Lease Audit</DialogTitle>
                <DialogContent style={{ marginBottom: theme.spacing(2) }}>
                    <DialogContentText>
                        Enter the name of the lease audit to get started.
                    </DialogContentText>
                    <TextField
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions
                    style={{
                        padding: theme.spacing(2),
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            setOpen(false)
                            setName('')
                        }}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            createLeaseAudit(name)
                            setOpen(false)
                            setName('')
                        }}
                        disabled={loading.createLeaseAudit}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
