import { Dispatch, SetStateAction, useState } from 'react'

import { IdentifiableObject, MoveOutRule, MoveOutStep } from '../models'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

export const useMoveOutRules = () => {
    const [moveOutRuleList, setMoveOutRuleList] = useState<MoveOutRule[]>([])
    const [
        selectedMoveOutRule,
        setSelectedMoveOutRule,
    ] = useState<MoveOutRule | null>(null)

    const getMoveOutRuleList = async () => {
        try {
            const res = await axiosInstance.get('lease/move-out-rule/', {
                params: { archived: false },
            })
            const rules: MoveOutRule[] = res.data
            setMoveOutRuleList(rules)
            return rules
        } catch (e) {
            toast.error('Error getting move out rules')
            return Promise.reject(e)
        }
    }

    const createMoveOutRule = async (request: CreateMoveOutRuleRequest) => {
        try {
            const res = await axiosInstance.post(
                'lease/move-out-rule/',
                request,
            )
            const moveOutRule: MoveOutRule = res.data
            _insertOrUpdateIdentifiable(moveOutRule, setMoveOutRuleList)
            return moveOutRule
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    const deleteMoveOutRule = async (moveOutRule: MoveOutRule) => {
        try {
            const res = await axiosInstance.post(
                `lease/move-out-rule/${moveOutRule.id}/archive_rule/`,
            )
            toast.success(`${moveOutRule.name} deleted successfully!`)
            _removeIdentifiable(moveOutRule, setMoveOutRuleList)
        } catch (e: any) {
            toast.error(e.response.data.message)
        }
    }

    const createOrUpdateMoveOutStep = async (
        request: CreateOrUpdateMoveOutStepRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'lease/move-out-rule/create_or_update_steps/',
                request,
            )
            const moveOutRule: MoveOutRule = res.data
            _insertOrUpdateIdentifiable(moveOutRule, setMoveOutRuleList)
            return moveOutRule
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    const deleteMoveOutStep = async (id: number) => {
        try {
            const body = {
                move_out_step: id,
            }
            const res = await axiosInstance.post(
                'lease/move-out-rule/delete_step/',
                body,
            )
            const moveOutRule: MoveOutRule = res.data
            _insertOrUpdateIdentifiable(moveOutRule, setMoveOutRuleList)
            return moveOutRule
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    const createOrUpdateVendorRule = async (
        request: CreateOrUpdateMoveOutVendorRuleRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'lease/move-out-rule/create_or_update_vendor_rule/',
                request,
            )
            const moveOutStep: MoveOutStep = res.data
            const moveOutRule = moveOutRuleList.find(
                (rule) => rule.id === moveOutStep.move_out_rule,
            )
            if (moveOutRule) {
                const updatedSteps: MoveOutStep[] = []
                moveOutRule.steps.forEach((step) => {
                    if (step.id === moveOutStep.id) {
                        updatedSteps.push(moveOutStep)
                    } else {
                        updatedSteps.push(step)
                    }
                })
                const updatedMoveOutRule = {
                    ...moveOutRule,
                    steps: updatedSteps,
                }
                _insertOrUpdateIdentifiable(
                    updatedMoveOutRule,
                    setMoveOutRuleList,
                )

                setSelectedMoveOutRule(updatedMoveOutRule)
            }
        } catch (e: any) {
            toast.error(e.response.data.message)
        }
    }

    const deleteMoveOutVendorRule = async (id: number) => {
        try {
            const body = {
                move_out_vendor_rule: id,
            }
            const res = await axiosInstance.post(
                'lease/move-out-rule/delete_vendor_rule/',
                body,
            )
            const moveOutStep: MoveOutStep = res.data
            const moveOutRule = moveOutRuleList.find(
                (rule) => rule.id === moveOutStep.move_out_rule,
            )
            if (moveOutRule) {
                const updatedSteps: MoveOutStep[] = []
                moveOutRule.steps.forEach((step) => {
                    if (step.id === moveOutStep.id) {
                        updatedSteps.push(moveOutStep)
                    } else {
                        updatedSteps.push(step)
                    }
                })
                const updatedMoveOutRule = {
                    ...moveOutRule,
                    steps: updatedSteps,
                }
                _insertOrUpdateIdentifiable(
                    updatedMoveOutRule,
                    setMoveOutRuleList,
                )

                setSelectedMoveOutRule(updatedMoveOutRule)
            }
        } catch (e: any) {
            toast.error(e.response.data.message)
        }
    }

    const scheduleMoveOut = async (request: ScheduleMoveOutRequest) => {
        try {
            const res = await axiosInstance.post(
                'lease/move-out-rule/schedule_move_out/',
                request,
            )
            return Promise.resolve()
        } catch (e: any) {
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue.id)
        })
    }

    return {
        moveOutRuleList,
        selectedMoveOutRule,
        setMoveOutRuleList,
        setSelectedMoveOutRule,
        getMoveOutRuleList,
        createMoveOutRule,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        deleteMoveOutRule,
        createOrUpdateVendorRule,
        deleteMoveOutVendorRule,
        scheduleMoveOut,
    }
}

export interface CreateMoveOutRuleRequest {
    name: string
    allow_weekends: boolean
}

export interface CreateOrUpdateMoveOutStepRequest {
    move_out_rule: number
    service?: number
    inspection_type?: number
    rank?: number
}

export interface CreateOrUpdateMoveOutVendorRuleRequest {
    move_out_step: number
    vendor: number
    rank?: number
}

export interface ScheduleMoveOutRequest {
    move_out_rule: number
    lease_ids: number[]
    schedule_name: string
    schedule_id: string
    start_date: string
}
