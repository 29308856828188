import React, { useState } from 'react'

import {
    Button,
    TextField,
    Box,
    Theme,
    makeStyles,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import {
    ApartmentVendorRuleDict,
    CreateOrUpdateAptVendorRuleRequest,
} from '../../hooks/useApartmentVendorRule'
import { ApartmentVendorRule, ListVendor } from '../../models'
import { Selector } from '../../components'

interface Props {
    vendorList: ListVendor[]
    theme: Theme
    selectedServiceId: number
    existingRules: ApartmentVendorRule[]
    createOrUpdateAptVendorRule: (
        request: CreateOrUpdateAptVendorRuleRequest,
    ) => Promise<ApartmentVendorRuleDict | null>
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    selector: {
        flex: 1,
        marginRight: theme.spacing(2),
    },
    bufferDays: {
        width: 120,
        marginRight: theme.spacing(2),
    },
    addButton: {
        minWidth: 'auto',
    },
    ezNowButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    },
}))

export const AddVendorServiceRule = (props: Props) => {
    const {
        vendorList,
        theme,
        selectedServiceId,
        existingRules,
        createOrUpdateAptVendorRule,
    } = props

    const classes = useStyles()

    const [selectedVendorId, setSelectedVendorId] = useState<number>(-1)
    const [bufferDays, setBufferDays] = useState<number>(1)

    const handleVendorChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        setSelectedVendorId(event.target.value as number)
    }

    const handleBufferDaysChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setBufferDays(parseInt(event.target.value) || 0)
    }

    const handleSubmit = async () => {
        if (selectedVendorId !== -1) {
            const request: CreateOrUpdateAptVendorRuleRequest = {
                service_id: selectedServiceId,
                vendor_id: selectedVendorId,
                buffer_days: bufferDays,
            }
            createOrUpdateAptVendorRule(request).then(() => {
                setSelectedVendorId(-1)
                setBufferDays(1)
            })
        }
    }

    const handleAddEZNow = async () => {
        const request: CreateOrUpdateAptVendorRuleRequest = {
            service_id: selectedServiceId,
        }
        createOrUpdateAptVendorRule(request)
    }

    const ezNowExists = existingRules.some((rule) => rule.vendor === null)

    return (
        <Box className={classes.root}>
            <Box className={classes.form}>
                <Selector
                    label="Vendor"
                    currentValue={selectedVendorId}
                    onChange={handleVendorChange}
                    data={vendorList}
                    getDisplayString={(s: ListVendor) => s.name}
                    size="small"
                    customStyle={{
                        formControl: {
                            flex: 1,
                            marginRight: '16px',
                        },
                    }}
                />
                <TextField
                    label="Buffer Days"
                    type="number"
                    value={bufferDays}
                    onChange={handleBufferDaysChange}
                    InputProps={{ inputProps: { min: 1 } }}
                    className={classes.bufferDays}
                    variant="outlined"
                    size="small"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={selectedVendorId === -1}
                    className={classes.addButton}
                    startIcon={<Add />}
                >
                    Add Vendor Rule
                </Button>
            </Box>
            {!ezNowExists && (
                <Button
                    variant="contained"
                    onClick={handleAddEZNow}
                    className={classes.ezNowButton}
                    startIcon={<Add />}
                    disabled={ezNowExists}
                >
                    Add EZNow Rule
                </Button>
            )}
        </Box>
    )
}
